import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useRouter } from 'next/router';
// import { withApollo } from '../../../config/apollo';
import { getCookie } from 'cookies-next';
import HeaderWithSidebar from '@app/components/templates/HeaderWithSidebar';
import Skeleton from '@app/components/atoms/Skeleton';
import AuthTwoColumns from '../../../components/templates/AuthTwoColumns';
import SignInForm from '../../../components/organisms/auth/SignInForm';
// import { redirectToRoute } from '../../../helpers';
import { useTranslation } from '../../../config/i18n';
import { goToDashboard, isUser, redirectToRoute } from '../../../helpers';
import { OK } from '../../../constants/guard-code';

const Login = () => {
  const { t } = useTranslation('common');

  const router = useRouter();
  const { query = {} } = router;
  const [isAuth, setIsAuth] = useState(false)
  const next = query.next || null;

  useEffect(() => {
    const accessToken = getCookie('token');
    
    if (!next) {
      if (!accessToken || accessToken === undefined || accessToken === null) {
        setIsAuth(false)
        redirectToRoute({}, 'login');
      }else {
        setIsAuth(true)
        redirectToRoute({}, 'opportunity_search');
      }
    }
  }, []);

  return (
    <>
      { isAuth && !next ?
        <HeaderWithSidebar contentPadding={0} >
          <Skeleton height="450px" />
        </HeaderWithSidebar>
      :
        <AuthTwoColumns title={t('login')} img="/images/login.png">
          <Head>
            <title>{t('login')}</title>
          </Head>
          <Container>
            <Typography variant="h4" component="h4" sx={{ marginBottom: '26px' }}>
              {t('login')}
            </Typography>
            <Grid item xs={12}>
              <SignInForm />
            </Grid>
          </Container>
        </AuthTwoColumns>
      }
    </>
  )
};


Login.guard = async (ctx, user) => {
  if (isUser(user)) {
    goToDashboard(ctx, user);
  }
  return OK;
}

export async function getServerSideProps() {
  return {
    props: {
      namespacesRequired: ['common', 'footer'],
    }
  }
}

export default Login;
