/* eslint-disable react/no-this-in-sfc */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { gql, useMutation, useApolloClient } from '@apollo/client';
import { useRouter } from 'next/router';
import { Formik } from 'formik';

import EmailIcon from '@mui/icons-material/Email';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Grid from '@mui/material/Grid';
import { IconButton } from '@mui/material';

import RoundedButton from '@app/components/atoms/RoundedButton';
import TextField from '../../../atoms/TextField';
import Link from '../../../atoms/Link';
import Checkbox from '../../../atoms/Checkbox';
import { SignInContainer, ButtonContainer, StyledForm } from './style';
import MutationsStatus from '../../../molecules/MutationsStatus';
import { useTranslation } from '../../../../config/i18n';
import { setAuthCookie, goToDashboard } from '../../../../helpers';
import Fragemets from '../../../../apollo/fragments';
// import LinkedinLogin from '../../../../pages/auth/social-auth/linkedin';

const SIGNIN_MUTATION = gql`
  mutation login($input: LoginInput) {
    login(input: $input) {
      access_token
      user {
        ...UserInformation
      }
    }
  }
  ${Fragemets.userInfo}
`;

const SOCIAL_LOGIN_MUTATION = gql`
  mutation socialLogin($input: SocialLoginInput) {
    socialLogin(input: $input) {
      access_token
      user {
        ...UserInformation
      }
    }
  }
  ${Fragemets.userInfo}
`;
// const getURLWithQueryParams = (base, params) => {
//   const query = Object.entries(params)
//     .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
//     .join("&");

//   return `${base}?${query}`
// }

const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64)
    .split('')
    .map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
    .join(''));

  return JSON.parse(jsonPayload);
}

const SignInForm = () => {
  const router = useRouter();

  const { query = {} } = router;

  const client = useApolloClient();
  const [
    SignInMutation,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation(SIGNIN_MUTATION);

  const [SocialLoginMutation, { loading: SLLoading, error: SLError, data: SLData }] = useMutation(SOCIAL_LOGIN_MUTATION);

  const { t } = useTranslation('common');

  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  // const [code, setCode] = React.useState(null);


  const getLinkedInAccessToken = async (queryCode) => {
    const postData = new URLSearchParams({
      'code': queryCode,
      'grant_type': 'authorization_code',
      'redirect_uri': "https://beta.scivenia.com/en/login",
      'client_id': "785fvcyqj71u60",
      'client_secret': "b1lmhBnkEwbAF2rv"
    })

    const response = await fetch('https://www.linkedin.com/oauth/v2/accessToken', {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': "application/x-www-form-urlencoded",
      },
      body: postData.toString(),
    })
    const { id_token, access_token } = await response.json();
    const { sub, name, email } = parseJwt(id_token);
    SocialLoginMutation({
      variables: {
        input: {
          id: sub,
          name,
          email,
          provider: "linkedin",
          accessToken: access_token
        }
      }
    })
  }

  useEffect(() => {

    if (query.code && query.state === 'DCEeFWf45A53sdfKef424') {
      getLinkedInAccessToken(query.code)
    }
    // access_token=cd98e156-a8d0-44e3-a6ee-1fcd245bf6aa
    // token_type=bearer&
    // expires_in=599&
    // tokenVersion=1&
    // persistent=true&
    // id_token=eyJraWQiOiJwcm9kdWN0aW9uLW9yY2lkLW9yZy03aGRtZHN3YXJvc2czZ2p1am84YWd3dGF6Z2twMW9qcyIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoiYzhkMDkyYk5rTHNOTXFKQndrd2otQSIsImF1ZCI6IkFQUC04TzVIN0wwMDJKRDFBWkwyIiwic3ViIjoiMDAwMC0wMDAyLTY4NTMtMDMxMCIsImF1dGhfdGltZSI6MTcwNDQ0NzY5NCwiaXNzIjoiaHR0cHM6XC9cL29yY2lkLm9yZyIsImV4cCI6MTcwNDUzNDgwMiwiZ2l2ZW5fbmFtZSI6IkRhcmtvIiwiaWF0IjoxNzA0NDQ4NDAyLCJmYW1pbHlfbmFtZSI6IkJvemhpbm9za2kiLCJqdGkiOiIwODJkMDVmNS1lODFhLTQ3YmEtODc1Ni0zN2ZjMjM5ZThjMzIifQ.LkufnpG4kC5HMFueU6RKi7QIFgO4is3BQTuO5yqs5tnPsG2tmkXxcUrdPKMstFMnj3-69YbSILLIsy6WdkvxPiYoxKIrBe_o8GK8HYXJ0FJfCyfFhggTZflz5e22KKN1cg8rbmXmKpfyVZqen2adIsm8PO7mhKcoUG6W3_pcFIXjAnMF7BHn4yPvJD4430YmvM4CXIs_D2WkSS74P_9EqI3-w91bD512e784_SSafkdHUct65Uj0_rZUn5lfkabZ4PvcRB2jiN4ZHD5efOzvfY-ZAitVM-LEJvMCWQ6gId7vReFRVhgftY9wSAHQSVYVwB5j473b2HtxtAqWIHuWtQ&
    // tokenId=408764786
    if (query.access_token && query.id_token) {

      const { sub, given_name, family_name } = parseJwt(query.id_token);
      SocialLoginMutation({
        variables: {
          input: {
            id: sub,
            name: given_name,
            email: `${given_name}.${family_name}@gmail.com`,
            provider: "orchid",
            accessToken: query.access_token
          }
        }
      })
    }
  }, [query])



  const SignIn = (input) => {
    SignInMutation({
      variables: { input },
    });
  };

  // const sendOrcidRequest = () => {
  //   const orcidLoginPromptPayload = {
  //     client_id: "APP-8O5H7L002JD1AZL2",
  //     redirect_uri: 'https://beta.scivenia.com/en/login?',
  //     scope: 'openid',
  //     response_type: 'token',
  //   };

  //   const authorizationBaseURL = "https://orcid.org/oauth/authorize";
  //   const orcidLoginPrompt = getURLWithQueryParams(authorizationBaseURL, orcidLoginPromptPayload);

  //   router.push(orcidLoginPrompt);
  // };



  // const sendLinkedInRequest = () => {
  //   const linkedInLoginPromptPayload = {
  //     response_type: "code",
  //     client_id: "785fvcyqj71u60",
  //     redirect_uri: "https://beta.scivenia.com/en/login",
  //     state: "DCEeFWf45A53sdfKef424",
  //     scope: "openid profile email",
  //   };

  //   const authorizationBaseURL = "https://www.linkedin.com/oauth/v2/authorization";
  //   const linkedInLoginPrompt = getURLWithQueryParams(authorizationBaseURL, linkedInLoginPromptPayload);

  //   router.push(linkedInLoginPrompt);
  //   // window.open(linkedInLoginPrompt, '_blank', '_blank');
  // };

  // const componentDidMount = () => {
  //   if (window.opener && window.opener !== window) {
  //     const code = this.getCodeFromWindowURL(window.location.href);
  //     window.opener.postMessage({'type': 'code', 'code': code}, '*')
  //     window.close();
  //   }
  //     window.addEventListener('message', this.handlePostMessage);
  // };

  // // eslint-disable-next-line no-unused-vars
  // const handlePostMessage = event => {
  //   if (event.data.type === 'code') {
  //     const { code } = event.data;
  //     this.getUserCredentials(code);
  //   }
  // };

  // // eslint-disable-next-line no-undef
  // const getCodeFromWindowURL = url => {
  //   const popupWindowURL = new URL(url);
  //   return popupWindowURL.searchParams.get("code");
  // };

  // const showPopup = () => {
  //   const { clientId, redirectUrl, oauthUrl, scope, state } = LinkedInApi;
  //   const linkedurl = `${oauthUrl}&client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUrl}`;
  //   const width = 450;
  //     const height = 730;
  //     const left = window.screen.width / 2 - width / 2;
  //     const top = window.screen.height / 2 - height / 2;
  //   window.open(
  //     linkedurl,
  //     'Linkedin',
  //     `menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=${
  //     width
  //     }, height=${
  //     height
  //     }, top=${
  //     top
  //     }, left=${
  //     left}`
  //   );
  // };

  // getUserCredentials = code => {
  //   axios
  //     .get(`${NodeServer.baseURL} + ${NodeServer.getUserCredentials}?code=${code}`)
  //     .then(res => {
  //       const user = res.data;
  //       this.setState({
  //         user,
  //         loaded: true
  //       })
  //       // Do something with user
  //     });
  // };

  if (mutationData) {
    const { access_token, user } = mutationData.login;
    setAuthCookie(access_token, rememberMe);

    let next = query.next || null;
    if (next) {
      next = decodeURIComponent(next);
    }

    client.cache.reset().then(() => {
      client.writeQuery({
        query: gql`
          {
            me
          }
        `,
        data: { me: user },
      });
      if (next) {
        router.push(next);
        // const nextRoute = JSON.parse(next);
        // redirectToRoute({}, nextRoute.name, { params: nextRoute.params, query: nextRoute.params });
      } else {
        goToDashboard({}, user);
      }
    });
  }

  if (SLData) {
    const { access_token, user } = SLData.socialLogin;
    setAuthCookie(access_token, rememberMe);

    let next = query.next || null;
    if (next) {
      next = decodeURIComponent(next);
    }

    client.cache.reset().then(() => {
      client.writeQuery({
        query: gql`
          {
            me
          }
        `,
        data: { me: user },
      });
      if (next) {
        router.push(next);
        // const nextRoute = JSON.parse(next);
        // redirectToRoute({}, nextRoute.name, { params: nextRoute.params, query: nextRoute.params });
      } else {
        goToDashboard({}, user);
      }
    });
  }

  return (
    <>
      <MutationsStatus loading={mutationLoading || SLLoading} error={mutationError || SLError} success={mutationData || SLData} />
      {/* <Button onClick={doSomeThing}></Button> */}
      <Grid container spacing={1}>
        <Formik
          initialValues={{ username: '', password: '' }}
          validate={(values) => {
            const errors = {};
            if (!values.username) {
              errors.username = t('validation.req');
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)) {
              errors.username = t('validation.email');
            }

            if (!values.password) {
              errors.password = t('validation.req');
            }
            return errors;
          }}
          onSubmit={(values) => {
            SignIn(values);
            // let next = null;
            // if (query && query.next) {
            //   next = query.next;
            //     const nextRoute = JSON.parse(next);
            //     console.log('next', next);
            //     console.log('nextRoute',nextRoute);
            //     redirectToRoute({}, nextRoute.name, nextRoute.params);
            // }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            /* and other goodies */
          }) => (
            <StyledForm>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  name="username"
                  label={t('email')}
                  value={values.username}
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.username && errors.username}
                  helperText={touched.username && errors.username}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        id="email-input-icon"
                        aria-label="email input icon"
                      >
                        <EmailIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="password"
                  label={t('password')}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        id="toggle-password-visibility"
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      // onMouseDown={()=> (setShowPassword(!showPassword))}
                      >
                        {showPassword ? <VisibilityIcon fontSize="small" /> : <VisibilityOffIcon fontSize="small" />}
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  checked={rememberMe}
                  onChange={(event) => {
                    setRememberMe(event.target.checked);
                  }}
                  inputProps={{
                    'aria-label': t('remember'),
                  }}
                  label={t('remember')}
                />
              </Grid>
              <ButtonContainer container xs={12} lg={12}>
                <Grid item>
                  <RoundedButton
                    sx={{
                      paddingLeft: '30px',
                      paddingRight: '30px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      backgroundColor: '#3949AB'
                    }}
                    id="submit"
                    type="submit"
                    disabled={mutationLoading}
                    onClick={handleSubmit}
                    variant="contained"
                    loading={mutationLoading}
                    fullWidth
                  >
                    {t('login')}
                    <OpenInNewIcon sx={{ marginLeft: '8px' }} fontSize='small' />
                  </RoundedButton>
                </Grid>
              </ButtonContainer>
              <ButtonContainer container xs={12} lg={12}>
                {/* <Grid item lg={6}>
                  <LoginWithButton
                    onClick={sendLinkedInRequest}
                    sx={{
                      borderRadius: "50px", color: '#3949AB', paddingLeft: '30px',
                      paddingRight: '30px',
                      paddingTop: '10px',
                      paddingBottom: '10px'
                    }}
                    variant="text" >
                    Login with  <LinkedInIcon sx={{ marginLeft: '8px' }} />
                  </LoginWithButton>
                </Grid>
                <Grid item lg={6}>
                  <LoginWithButton
                    onClick={sendOrcidRequest}
                    variant="text"
                    sx={{
                      color: '#3949AB',
                      borderRadius: "50px",
                      paddingLeft: '30px',
                      paddingRight: '30px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      display: 'flex',
                      flexDirection: 'row',

                    }} >
                    Login with
                    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="20" viewBox="0 0 63 20" fill="none" style={{ marginLeft: '8px', marginBottom: '5px' }}>
                      <path d="M7.6746 4.27588C12.3771 4.27588 15.3937 7.68928 15.3937 11.687C15.3937 15.5944 12.5102 19.0979 7.6746 19.0979C2.9724 19.1426 0 15.6395 0 11.7318C0 7.68928 3.06106 4.27588 7.6746 4.27588ZM7.6746 17.2562C10.8688 17.2562 13.2642 14.9206 13.2642 11.7318C13.2642 8.54271 10.913 6.20734 7.6746 6.20734C4.4807 6.20734 2.12946 8.54271 2.12946 11.7315C2.12946 14.8755 4.4807 17.2562 7.6746 17.2562ZM21.5759 4.56092C24.4779 4.56092 26.3086 6.11014 26.3086 8.54442C26.3086 10.182 25.4603 11.4214 23.8975 12.0411C25.1923 12.8819 25.9962 14.21 27.1122 15.9804C27.7374 16.9541 28.0946 17.4409 29.0769 18.8131H26.6214L24.7011 15.892C22.7815 12.9706 21.9331 12.6163 20.8613 12.6163H20.0129V18.8131H17.9593V4.56092H21.5759ZM20.0132 10.7578H21.3527C23.5848 10.7578 24.2102 9.60678 24.1655 8.45605C24.1655 7.12803 23.3616 6.28687 21.3527 6.28687H20.0129V10.7578H20.0132ZM41.7727 7.72406C40.0096 6.69391 38.5107 6.20135 36.8795 6.20135C33.6175 6.20135 31.193 8.53016 31.193 11.7095C31.193 14.9334 33.5294 17.1727 36.9237 17.1727C38.5107 17.1727 40.2738 16.5904 41.905 15.5605V17.8443C40.4945 18.6053 38.9956 19.0982 36.7472 19.0982C31.4133 19.0982 29.0769 14.9337 29.0769 11.8886C29.0769 7.49973 32.339 4.27588 36.8795 4.27588C38.3342 4.27588 39.8331 4.63418 41.7727 5.44028V7.72406Z" fill="#A6A8AB" />
                      <path d="M49.8867 4.56055H55.4056C60.6597 4.56055 62.9998 8.32285 62.9998 11.6867C62.9998 15.3603 60.1301 18.8128 55.4498 18.8128H49.8867V4.56055ZM51.9178 16.9537H55.1847C59.8208 16.9537 60.8804 13.4129 60.8804 11.6425C60.8804 8.76552 59.0702 6.33096 55.0966 6.33096H51.962V16.9537H51.9181H51.9178Z" fill="#A5CD39" />
                      <path d="M46.7832 18.8129H44.7228V4.38968H46.7832V12.4077V18.8129ZM47.0968 1.3437C47.0968 2.10534 46.4697 2.68768 45.753 2.68768C44.9916 2.68768 44.4092 2.06059 44.4092 1.3437C44.4092 0.627098 45.0363 0 45.753 0C46.5144 0 47.0968 0.627098 47.0968 1.3437Z" fill="#A5CD39" />
                    </svg>
                  </LoginWithButton>
                </Grid> */}
              </ButtonContainer>
              <SignInContainer container item xs={12} spacing={1}>
                <Grid item lg={12} xs={12}>
                  {t('no_account')}{' '}
                  <Link href="register" passHref>
                    {t('register-new')}
                  </Link>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <Link href="pass_forgot" passHref>
                    {t('forgot_pass')}
                  </Link>
                </Grid>
              </SignInContainer>
            </StyledForm>
          )}
        </Formik>
      </Grid>
    </>
  );
};

SignInForm.propTypes = {};

export default SignInForm;
