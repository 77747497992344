import styled from 'styled-components';
import FCLabel from '@mui/material/FormControlLabel';
import { deviceSizes } from '../../../config/devices'

export const FormControlLabel = styled(FCLabel)`
  && {
    &.MuiFormControlLabel-root {
      align-items: center;
    }
    @media (max-width: ${deviceSizes.mobileL}) {
      margin-top: 1rem;
      .MuiFormControlLabel-label {
      margin-left: 0;
      }
    }
  }
`;
